<template>
  <main class="about-page">
    <a class="whatsappWrapper" href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!"><img src="../assets/img/WhatsApp.svg"/></a>
    <div class="container">
      <!-- /.main-hero -->

      <!-- Right Text Section -->
      <div class="about-us">
        <div class="container">
          <div class="block-1">
            <div class="content">
              <h2>Discotheque</h2>
              <p>
                The pleasure of listening to enjoyable music, accompanied by
                great food and good company in an elegant and sophisticated
                setting. The Impair Disco features the best sound and lighting
                group in Bhuj and Kutch Region where Has been installed by the
                most famous specialists in the world and the experts in this
                field. And the presence of the best DJ in Bhuj to ensure the
                most beautiful atmosphere of the at all as we distinguished
                service and management with sufficient experience to satisfy all
                our members.
              </p>
            </div>
          </div>
          <!-- /.block-1 -->

          <div class="block-2">
            <div class="about-image">
              <img
                src="../assets/img/final_3d_images/low/img081.jpg"
                alt="Discotheque"
                class="img-cover"
              />
            </div>
          </div>

          <!-- /.block-2 -->

          <div class="clearfix"></div>
        </div>
        <!-- /.container -->
      </div>

      <!-- Left Text Section -->
      <div class="about-us">
        <div class="container">
          <div class="block-1" style="margin: 0px; width: 43%">
            <div class="about-image">
              <img
                src="../assets/img/final_3d_images/low/img080.jpg"
                alt="Discotheque Bar"
                class="img-cover"
              />
            </div>
          </div>
          <!-- /.block-1 -->

          <div
            style="margin-left: 100px; weight: calc(43% - 200px)"
            class="block-2"
          >
            <div class="content">
              <p>
                Time Square Club’s Discotheque is the most exclusive &amp;
                luxurious Discotheque in Bhuj, it is the heart and soul of the
                Indian nightlife. The venue encompasses everything a member
                would expect in night life. World class sound and stunning
                lighting system, Top and Most famous DJ (DJs) in Bhuj and
                playing the nicest Indian and International music. All that
                aligned with its unequalled service will ensure that each member
                will leave with an exceptional experience, great service, great
                quality and great entertainment…
              </p>
            </div>
          </div>
          <!-- /.block-2 -->

          <div class="clearfix"></div>
        </div>
        <!-- /.container -->
      </div>

      <!-- Right Text Section -->
      <div class="about-us">
        <div class="container">
          <div class="block-1">
            <div class="content">
              <h2>Cinema</h2>
              <p>
                Don’t just watch the movie, become part of it! Time Square
                Club’s Dolby Atmos Cinema provides the most immersive cinematic
                experience in the Kutch region. We welcome and invite all member
                to Enjoy the ultimate first class movie &amp; enjoy an engaging
                and memorable experience from the moment you step through the
                doors at Time Square Club’s Cinema. In Cinema, we pamper you
                with the most comfortable rooms, spacious and reclining seats
                for you to enjoy the best cinema. Cinema has been custom
                designed to put viewers in the middle of the action. The cinema
                is designed not only to bring the member closer to the screen,
                but to locate them better in relation to it, which creates an
                immersive experience regardless of where you sit.
              </p>
            </div>
          </div>
          <!-- /.block-1 -->

          <div class="block-2">
            <div class="about-image">
              <img
                src="../assets/img/final_3d_images/low/img009.jpg"
                alt="Cinema"
                class="img-cover"
              />
            </div>
          </div>

          <!-- /.block-2 -->

          <div class="clearfix"></div>
        </div>
        <!-- /.container -->
      </div>

      <!-- Left Text Section -->
      <div class="about-us">
        <div class="container">
          <div class="block-1" style="margin: 0px; width: 43%">
            <div class="about-image">
              <img
                src="../assets/img/final_3d_images/low/img083.jpg"
                alt="Cinema Waiting"
                class="img-cover"
              />
            </div>
          </div>
          <!-- /.block-1 -->

          <div
            style="margin-left: 100px; weight: calc(43% - 200px)"
            class="block-2"
          >
            <div class="content">
              <p>
                Our cinema’s Screen quality, 4k technology, the best fidelity,
                more pixel quantity, twice the resolution than a traditional
                digital projector. The Cinema has revolutionized the experience
                of going to unmatched brightness and clarity. In the Cinema
                Enjoy the Amazing Dolby Atmos sounds, pinpoint accuracy, perfect
                pitch, custom listening environment, the more visceral
                experience and the best sound ever.
              </p>
            </div>
          </div>
          <!-- /.block-2 -->

          <div class="clearfix"></div>
        </div>
        <!-- /.container -->
      </div>

      <!-- Full Width Image -->
      <img
        src="../assets/img/final_3d_images/low/img084.jpg"
        alt="Cinema Waiting Sofa"
        class="about-page-img img-cover"
      />

      <!-- /.info -->
    </div>
    <!-- /.container -->
  </main>
  <!-- /main -->
</template>

<script>
export default {
  name: "",
};
</script>

<style>
</style>